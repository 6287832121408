// authentication.js

import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER, REGISTER_SUCCESS, RESET_SUCCESS, RESET_AUTH_DATA } from './types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';
// import firebase from "firebase";
// import { resetReducerData } from "./index";

export const registerUser = (user, history) => dispatch => {
    axios.post('/mystoreauth/register', user)
        .then(res => {
            history.push('/')
            console.log("register successfull", res)
            dispatch({
                type: REGISTER_SUCCESS
            })
        })
        .catch(err => {
            console.log("Error in registerUser:", err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const checkResetOTP = (email) => dispatch => {
    axios.post('/mystoreauth/checkOTP', email)
        .then(res => {
            console.log("checkOTP - ", res.data)
            const { token } = res.data;
            if(token){
                setLocalstoreData(res.data)
                dispatch(setCurrentUser(jwt_decode(token)));
            }
            dispatch(resetSuccess(res.data))
        })
        .catch(err => {
            console.log("Error in checkResetOTP:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const updateLoginPassword = (email) => dispatch => {
    axios.post('/mystoreauth/updatePassword', email)
        .then(res => {
            console.log("updatePassword - ", res.data)
            const { token } = res.data;
            if(token){
                setLocalstoreData(res.data)
                dispatch(setCurrentUser(jwt_decode(token)));
            }
            dispatch(resetSuccess(res.data))
        })
        .catch(err => {
            console.log("Error in updateLoginPassword:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const resetLoginPassword = (email) => dispatch => {
    axios.post('/mystoreauth/resetpassword', email)
        .then(res => {
            // console.log("1111 - ", res.data)
            dispatch(resetSuccess(res.data))
            //showStatusMessage(res?.data)
        })
        .catch(err => {
            console.log("Error in resetLoginPassword:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const resetRapoPassword = (email) => dispatch => {
    axios.post('/mystoreauth/resetRapoPassword', email)
        .then(res => {
            // console.log("1111 - ", res.data)
            const { token } = res.data;
            if(token){
                setLocalstoreData(res.data)
                dispatch(setCurrentUser(jwt_decode(token)));
            }
            dispatch(resetSuccess(res.data))
            showStatusMessage(res?.data)
        })
        .catch(err => {
            console.log("Error in resetLoginPassword:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const changeLoginPassword = (data) => dispatch => {
    axios.post('/mystoreauth/changePassword', data)
        .then(res => {
            console.log("changePassword - ", res.data)
            const { token } = res.data;
            if(token){
                setLocalstoreData(res.data)
                dispatch(setCurrentUser(jwt_decode(token)));
            }
            //dispatch(resetSuccess(res.data))
            showStatusMessage(res?.data)
        })
        .catch(err => {
            console.log("Error in changePassword:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const loginUser = (user) => dispatch => {
    axios.post('/mystoreauth/login', user)
        .then(res => {
            console.log("Response:loginUser", res.data);
            // console.log("1111 - ", res.data);
            setLocalstoreData(res.data)
            const { token } = res.data;
            dispatch(setCurrentUser(jwt_decode(token)));
        })
        .catch(err => {
            console.log("Error in loginUser:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response?.data
            });
        });
}

export const loginWithToken = (resdata) => dispatch => {
    //setLocalstoreData(res.data)
    const token = "Bearer " + resdata.token;
    const rtoken = resdata.rtoken;
    if(token){
        localStorage.setItem('jwtToken', token);
        if(rtoken){
            localStorage.setItem('jwtrToken', rtoken);
        }
        const decoded = jwt_decode(token);
        let token_expiration_time = decoded.exp * 1000;
        localStorage.setItem("token_expiration_time", token_expiration_time);
        setAuthToken(token);
        //setTimer(timeout);
        dispatch(setCurrentUser(decoded));
        console.log("token decoded", decoded)
    }
}

export const refreshAccessToken = (rToken, timeout) => dispatch => {
    //console.log("Inside RefreshAccessToken call")
    clearInterval(logout_interval);

    axios.post('/mystoreauth/token', { token: rToken, timeout: timeout })
        .then(res => {
            //console.log("Response:refreshAccessToken", res.data);
            const aToken = "Bearer " + res.data.AccessToken;
            localStorage.setItem("extended_expiration_time", 0);
            setAuthToken(aToken);
            localStorage.setItem("jwtToken", aToken)
            localStorage.setItem("token_expiration_time", jwt_decode(aToken).exp * 1000);

            //console.log("##### after refresh token ####")
            setTimer()
            dispatch(setCurrentUser(jwt_decode(aToken)));
        })
        .catch(err => {
            console.log("Error in refreshAccessToken:", err.response)
        })
};

export const loginGoogleUser = (token) => dispatch => {
    axios.post('/mystoreauth/googlelogin', token)
        .then(res => {
            console.log("Response:loginGoogleUser", res.data);
            setLocalstoreData(res.data)
            const { token } = res.data;
            dispatch(setCurrentUser(jwt_decode(token)));
        })
        .catch(err => {
            console.log("Error in loginGoogleUser:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response?.data
            });
        });
}

export const loginFacebookUser = (token) => dispatch => {
    axios.post('/mystoreauth/facebooklogin', token)
        .then(res => {
            console.log("Response:loginFacebookUser", res.data);
            setLocalstoreData(res.data)
            const { token } = res.data;
            dispatch(setCurrentUser(jwt_decode(token)));
        })
        .catch(err => {
            console.log("Error in loginFacebookUser:", err.response)
            dispatch({
                type: GET_ERRORS,
                payload: err.response?.data
            });
        });
}

export const setLocalstoreData = (resdata) => {
    const { token } = resdata;
    const { rtoken } = resdata;
    const { timeout } = resdata;
    if(token && rtoken){
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('jwtrToken', rtoken);
        const decoded = jwt_decode(token);
        let token_expiration_time = decoded.exp * 1000;
        localStorage.setItem("token_expiration_time", token_expiration_time);
        setAuthToken(token);
        setTimer(timeout);
    }
}

/**
 * this helper method can be called from any class to reset session expiration popup
 * if no parameter is sent then it calculates timeout from aToken expiration
 */
let logout_popup_time;
export const setTimer = (timeout) => {
    //if timeout is not sent we calculate timeout from 
    //aToken itself when it will expire from current time
    let timeout_ms;
    const aToken = localStorage.getItem("jwtToken");
    const decoded = jwt_decode(aToken);

    if (!timeout) { //auto session extension && on page refresh
        timeout = (decoded.exp * 1000 - Date.now()) / (1000 * 60); //in minutes
    } else { //when continue button is clicked & first time on login, db timeout is taken
        localStorage.setItem("timeout", timeout);
    }
    timeout_ms = parseInt(timeout) * 60 * 1000;
    // we don't need to set token_expiration_time here, it is set when ever we set aToken itself    

    clearTimeout(logout_popup_time);
    clearTimeout(logout_interval);
    logout_popup_time = setTimeout(() => setShowContinueLogin(), timeout_ms - 30 * 1000);
}

export const updateTimeout = (timeout) => {
    let l_timeout = localStorage.getItem('timeout');
    let n_timeout = parseInt(timeout);
    if (l_timeout !== n_timeout) {
        setTimer(n_timeout);
    }
}

let logout_interval;
export const setShowContinueLogin = () => {
    let extended_expiration_time = parseInt(localStorage.getItem("extended_expiration_time"));
    let token_expiration_time = parseInt(localStorage.getItem("token_expiration_time"));
    if (extended_expiration_time > token_expiration_time) {
        if(document.querySelector(".continue_login")){
            document.querySelector(".continue_login").click();
        }
    } else {
        document.querySelector(".login-popup").style.display = "block";
        document.querySelector(".login_checkup").style.display = "block";
        let timer_time = 30;

        if (document.getElementById("logout_timer")) {
            document.getElementById("logout_timer").innerHTML = timer_time;
        }
        clearInterval(logout_interval);
        logout_interval = setInterval(() => {
            timer_time = timer_time - 1
            //console.log("logout_interval", timer_time)
            if (timer_time > 0) {
                document.getElementById("logout_timer").innerHTML = timer_time;
            }
            else if (timer_time === 0) {
                clearInterval(logout_interval);
                console.log("auto logging out as popup timer expired....")
                //logoutUser()
                document.querySelector(".logout_now").click();
            }
        }, 1000)
    }
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const resetAuthData = decoded => {
    return {
        type: RESET_AUTH_DATA,
        payload: decoded
    }
}

export const resetSuccess = decoded => {
    return {
        type: RESET_SUCCESS,
        payload: decoded
    }
}

export const logoutUser = () => dispatch => {
    console.log("logoutUser is called....");
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtrToken');
    localStorage.removeItem('timeout');
    localStorage.removeItem("extended_expiration_time");
    localStorage.removeItem("token_expiration_time");
    if(document.querySelector(".login-popup")){
        document.querySelector(".login-popup").style.display = "none";
    }
    if(document.querySelector(".login_checkup")){
        document.querySelector(".login_checkup").style.display = "none";
    }
    setAuthToken(false);
    dispatch(setCurrentUser({}));
}

// export const getLoginUserToken = () => dispatch => {
//     console.log("In getLoginUserToken");
//     //console.log("#####2")
//     firebase.auth().currentUser.getIdToken(true).then(idToken => {
//         // console.log(idToken)
//         var user = { "token": idToken }
//         axios.post('/mystoreauth/tokenLogin', user)
//             .then(res => {
//                 // console.log("1111 - ", res.data)
//                 const { token } = res.data;
//                 localStorage.setItem('jwtToken', token);
//                 setAuthToken(token);
//                 dispatch(setCurrentUser(jwt_decode(token)));
//             })
//             .catch(err => {
//                 console.log("Error in getLoginUserToken:", err.response)
//             });
//     })
// }

export const showStatusMessage = (res) => {
    let _div = document.querySelector(".popup_div .msg_reset")
    if(res?.status === 'ok'){
        let alertSuccess = document.querySelector(".alertSuccess");
        alertSuccess.classList.remove("d-none");
        alertSuccess.innerHTML = res?.msg
        if (document.querySelector(".popup_div")) {
            document.querySelectorAll('.popup_div').forEach(div=>{
              div.style.display = "none";
            })
        }
        if (document.querySelector(".popup_layer")) {
            document.querySelectorAll('.popup_layer').forEach(div=>{
              div.style.display = "none";
            })
        }
        setTimeout(() => {
            alertSuccess.classList.add("d-none");
        }, 3000);  
        _div.innerHTML = "" 
    } else {
        _div.style.color = "red";
        _div.style.display = "block";
        _div.innerHTML = res?.msg
        setTimeout(() => {
            _div.style.display = "none";
        }, 8000);
    }
}
