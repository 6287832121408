// authReducer.js
import { SET_CURRENT_USER, REGISTER_SUCCESS, REFRESH_TOKEN, RESET_SUCCESS, RESET_AUTH_DATA } from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
    isAuthenticated: false,
    user: {},
    registerStatus: false,
    resetStatus: false,
    resetdata: {},
    TOKEN: {}
}


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        case REGISTER_SUCCESS:
            console.log(state.registerStatus, 'reducer')
            return {
                ...state,
                registerStatus: true
            }
        case RESET_SUCCESS:
            //console.log(state.resetStatus,'reducer')
            return {
                ...state,
                resetdata: action.payload,
                resetStatus: true
            }
        case REFRESH_TOKEN: {
            let _TOKEN = { ...state.TOKEN };
            console.log("New Access Token added to Reducer", action.payload);
            _TOKEN.aToken = action.payload.AccessToken;
            return {
                ...state,
                TOKEN: _TOKEN
            }
        }
        case RESET_AUTH_DATA:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                registerStatus: false,
                resetStatus: false,
                resetdata: {},
                TOKEN: {}
            }
        default:
            return state;
    }
}

export default authReducer;