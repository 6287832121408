import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/app";
import {
  createStore,
  applyMiddleware,
  // compose
} from "redux";
import thunk from "redux-thunk";

import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import rootReducer from "./reducers";
// import rootReducer from './reducers/components_reducer';
import setAuthToken from "./setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authentication";
import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger"; 

export const store = createStore(
  rootReducer,
  //compose(applyMiddleware(thunk)),
  composeWithDevTools(
    // applyMiddleware(thunk, logger)
    applyMiddleware(thunk)
    // other store enhancers if any
  )
);

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
  }
}

ReactDom.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
