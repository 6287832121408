import React, { useRef, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { callApi } from "../actions/components_action";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import loadable from "@loadable/component";

import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import LoadingBar from "react-top-loading-bar";
import {
  saveToRedux,
  resetCompData
} from "../actions/components_action";
import {
  refreshAccessToken,
  logoutUser,
  setTimer,
  resetAuthData
} from "../actions/authentication";
import { resetReducerData } from "../actions/index";

import DynamicRouter from "./DynamicRouter";
import { isTokenValid, checkPath } from "./event_functions";

const Home = loadable(() => import("./Home/Home"));
const Container = loadable(() => import("./container/Container"));
const SETDB = loadable(() => import("./container/SETDB"));
//const Pages = loadable(() => import("./pages/Pages"));
const Login = loadable(() => import("./Login"));
const Register = loadable(() => import("./Register"));
const Forgot = loadable(() => import("./Forgot"));

function App(props) {
  const { isAuthenticated } = props._auth;
  const barColor = "#f11946";
  const loader_ref = useRef(null);

  //console.log("_DATA", props._DATA)
  useEffect(() => {
    if (tokenCheck()) {
      setTimer();
    }
    if (!props._ENTITY_FIELD["theme"] && window.location.pathname.indexOf('/update_resources/') === -1) {
      loadTheme();
    }
  }, []); // eslint-disable-line, react-hooks/exhaustive-deps

  /**
   *
   * @returns true if token is valid
   * @returns false if token has expired and automatically logs out
   *
   */
  const tokenCheck = () => {
    let _token = localStorage.getItem("jwtToken");
    if (_token && !isTokenValid(_token)) {
      //if token exists but it is not valid, we need to logout
      props.logoutUser();
      // props.resetReducerData();
      props.resetAuthData();
      props.resetCompData();
      return false;
    } else if (!_token) {
      //if token doesn't exist that means user is not logged in nothing to do
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });

  // User has switched back to the tab
  const onFocus = () => {
    if (tokenCheck()) {
      setTimer();
    } 
    // console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    // console.log("Tab is blurred");
  };

  const loadTheme = () => {
    props.callApi({
      operation: "/get_entity_list_for_field",
      params: {
        field_id: "theme",
        //req_fields: ["style", "name"],
        ename: "Theme",
        filter_by: { status: "Enabled" },
      },
    });
  };

  return (
    <div className="w-100">
      <LoadingBar color={barColor} ref={loader_ref} />
      <BrowserRouter>
        <Switch>
          <Route path={["/e", "/ed"]}>
            {isAuthenticated ? (
              <Container loader_ref={loader_ref} />
            ) : (
              <Home loader_ref={loader_ref} />
            )}
          </Route>
          {/* <Route path={["/update_resources"]}>
            <SETDB loader_ref={loader_ref} />
          </Route> */}
          <Route path={["/"]}>
            {props._DATA?.display_template === "setDB" ? (
              <SETDB loader_ref={loader_ref} data={props._DATA} />
            ) : (
              <DynamicRouter loader_ref={loader_ref} />
            )}
          </Route>
          {/* <Route path="/reset-password">
            <Home rel="update-password" />
          </Route> */}
        </Switch>
      </BrowserRouter>
      {props._DATA?.display_template !== "setDB" ?
      <div className="login-popup">
        <Register loader_ref={loader_ref} />
        <Login loader_ref={loader_ref} />
        <Forgot
          loader_ref={loader_ref}
          path={
            window.location.pathname.indexOf("/reset-password") > -1
              ? window.location.pathname
              : null
          }
        />
      </div> : null }
    </div>
  );
}

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _ENTITY_FIELD: state.entity.ENTITY_FIELD,
  _DATA: state.entity.DATA,
});

export default connect(mapStateToProps, {
  callApi,
  refreshAccessToken,
  resetReducerData,
  saveToRedux,
  logoutUser,
  resetAuthData,
  resetCompData
})(App);
