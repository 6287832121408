import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
// import   PageRoutesData   from "./PageRoutesData";
import AppliedRoutes from "./AppliedRoutes.tsx";
import { callApi } from "../actions/components_action";
import loadable from "@loadable/component";

const DynamicRouter = (props) => {
  const prd = [
    {
      component: loadable(() => import("./Home/Init")),
      path: ["/init"],
      redirect: false,
      pathTo: "",
    },
  ];

  const [PageRoutesData, setPageRoutesData] = useState(prd);

  useEffect(() => {
    if (props._ENTITY_FIELD["pages_list"]) {
      // console.log("######## props._ENTITY_FIELD['pages_list']:",props._ENTITY_FIELD['pages_list'])
      let prds = [
        {
          component: loadable(() => import("./Home/Home")),
          path: "/",
          redirect: false,
          pathTo: "",
        },
      ];
      //let isSlash = false
      props._ENTITY_FIELD["pages_list"].forEach((page) => {
        let prd = {
          component: loadable(() => import("./pages/Pages")),
          path: page.Slug,
          redirect: false,
          pathTo: "",
        };
        prds.unshift(prd);
      });
      setPageRoutesData(prds);
      //console.log("routes pages_list ",prds)
    }
  }, [props._ENTITY_FIELD["pages_list"]]); // eslint-disable-line, react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props._ENTITY_FIELD["pages_list"]) {
      // console.log("pages_list loading...")
      load_pages_api();
    }
  }, []); // eslint-disable-line, react-hooks/exhaustive-deps

  useEffect(() => {
    //console.log(props._DATA)
    if (props._DATA?.msg && props._DATA.query.params.ename === "Page") {
      //load_pages_api();
    }
  }, [props._DATA]); // eslint-disable-line, react-hooks/exhaustive-deps

  const load_pages_api = () => {
    props.callApi({
      operation: "/get_entity_list_for_field",
      caller_ref: "DynamicRouter.get_pageslist",
      params: {
        field_id: "pages_list",
        ename: "Page",
      },
    });
  };

  return (
    <Fragment>
      <Switch>
        {PageRoutesData?.map(({ component, path, redirect, pathTo }) => {
          if (redirect) {
            return <Redirect from={path} to={pathTo} key={path} />;
          }
          return (
            <AppliedRoutes loader_ref={props.loader_ref} path={path} component={component} key={path} props />
          );
        })}
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  // console.log("NAVBAR state change", state.entity.NAVBAR)
  return {
    _DATA: state.entity.DATA,
    _ENTITY_FIELD: state.entity.ENTITY_FIELD,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(DynamicRouter);
