import Axios from "axios";
import {logoutUser} from "./authentication";

export function set_display_template(dt) {
  return (dispatch) => { //, getState
    dispatch({ type: "display_template", payload: dt });
  };
}

export function resetSecureData() {
  return { type: "reset_data", payload: null };
}

export function checkCache(query, getState) {
  if (query.operation === "/products_details") {
    //console.log(query, getState().item.PDs);
    let _pid = query.params.pid;
    let m_PD = getState().item.PDs.find((pd) => {
      //console.log(pd, pd.product[0].id, _pid);
      return pd.product[0].id === _pid;
    });
    //console.log(m_PD);
    if (!m_PD) {
      //.length === 0) {
      //PD.length === 0 means PD doesn't exist
      //console.log("PD doesn't exist");
      return null;
    } else {
      m_PD.source = "cache";
      //      m_PD[0].source = "cache";
      //console.log("PD exists after", m_PD);
      return m_PD; //[0];
    }
  } else if (query.operation === "/departments_of_business") {
    let _DB = getState().item.DB;
    //console.log(_DB);
    if (
      _DB &&
      _DB.business &&
      _DB.business.bid === query.params.bid &&
      _DB.business.pid === query.params.pid
    ) {
      _DB.source = "cache";
      return _DB;
    } else {
      return null;
    }
  }
}

export function resetReducerData() {
  return { type: "USER_LOGOUT", payload: null };
}

export function callApi(query, global) {
  ////console.log("Inside callApi called...", global);
  return (dispatch, getState) => {
    if (global !== undefined) {
      //console.log("are we setting global now???");
      dispatch({ type: "global", payload: global });
    }
    try {
      
      let entity = checkCache(query, getState);
      if (entity) {
        //console.log("Loading Entity from Cache",entity);
        dispatch({
          type: entity.display_template,
          payload: entity,
        });
      } else {
        // console.log(">>>> 1. calling backend now to fetch data...", query);
        let url = ""
        if (
          query.operation === "/address_lookup" ||
          query.operation === "/geo_location"
        ) {
          //url = "http://localhost:3001/lookup";  //local server
          //url = "http://dev.api.rapo.store/lookup"; //development server
          url = "/lookup"; //production server
        } else {
          // url = "http://localhost:3001/api"; //local server
          //url = "http://dev.api.rapo.store/api";  //development server
          url = "/api"; //production server
        }
//        Axios.post(url, query, { timeout: 50000 }).then((res) => {
        Axios.post(url, query).then((res) => {
          if (query === undefined) {
            //console.log("query is undefined");
            return null;
          } else {
            //const resp = ;
            //console.log(resp);
            // console.log(">>>> 2. In callApi ... Query:", query, "Response:", res.data);
            // //console.log(JSON.stringify(res.data));
            if (res.data.data) {
              res.data.data.display_template = res.data.display_template;
              res.data.data.source = "server";
              res.data.data.query = query;
              //console.log(res.data, res.data.data.display_template,"index.callApi response data....");
              if (res.data.data.display_template) {
                //console.log('payload:',res.data.data, 'type:',res.data.data.display_template,"before calling dispath");
                dispatch({
                  type: res.data.data.display_template,
                  payload: res.data.data,
                });
              }
            } else if (
              res.data && (res.data.msg?.toLowerCase() === "not authorized" || res.data.msg?.toLowerCase() === "session expired") 
            ) {
              console.log("$$$$$$$$$$$$$$$$$",res.data);
              logoutUser();
              if ( window.location.pathname !== "/") {
                //window.location.href = window.location.origin + "/";
              }
              
            } else {
              console.log("No where to go so here...")
            }
          }
        }).catch((err)=>{
          console.log(err,"Error in index.js")
        });
      }
    } catch (e) {
      console.log(e, "index.callApi Error");
      //dispatch({ type: types.GET_DATA_FAIL, payload: e })
    }
  };
}

// export function callFirebase(query) {
//   return (dispatch) => { //, getState
//     query.get().then(function (sh) {
//       let bz_setup;
//       let docdata = sh.data();
//       if(docdata) bz_setup = docdata.bz_setup;
//       if (!bz_setup) {
//         let defSchedule = [{ dow: "ASAP", isSel:true, slots: [] }, { dow: "Mon", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Tue", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Wed", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Thu", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Fri", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Sat", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }, { dow: "Sun", isSel:true, slots: [{ s_time: "09:00", e_time: "22:00" }] }]

//         bz_setup = { binfo: { name: "", type: 0, address: {} }, ful_selection: [{ ful_name: "Home Delivery", ful_type: 0, is_selected: true, config: { zones:[] } }, { ful_name: "Store Pickup", ful_type: 1, is_selected: true, schedule: defSchedule }] }
//       }
//         dispatch({ type: 'bz_setup', payload: bz_setup });
//     });
//   }
// }

export function saveAccountClickedRoute(data) {
  return { type: "clicked_account_route", payload: data };
}