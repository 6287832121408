import React from "react";
import { Route } from "react-router-dom";

// export default ({ component: C, props: cProps, ...rest }:any) => (
//   <Route {...rest} exact render={(props) => <C {...props} {...cProps} />} />
// );


const AppliedRoutes = ({ component: C, props: cProps, ...rest }:any) => (
  <Route {...rest} exact render={(props:any) => <C {...props} {...cProps} />} />
);

export default AppliedRoutes;